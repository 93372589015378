/* eslint-disable import/no-anonymous-default-export */
export default {
  page: {
    mainPage: {
      welcomeMessage: 'Welcome to the Styngr CMS',
      casualGreetingsHi: 'Hi',
    },
    styngs: {
      newStyng: 'New Styng',
      styngError: 'Styng is required',
      supportedFiles: 'Supported files : AAC, MP3',
      create: {
        uploadStyng: 'Upload styng',
        uploadCover: 'Upload cover',
        unavailable: 'Unavailable',
        withdrawnMessage: 'Track was withdrawn',
        fields: {
          title: 'Styng title',
          genre: 'Genre',
          album: 'Album',
          artist: 'Artist',
          duration: 'Duration',
          nftName: 'NFT name',
          application: 'Application',
          gameEngine: 'Game engine',
          recordLabel: 'Record label',
          songName: 'Song name',
        },
      },
    },
    stynglists: {
      newStynglist: 'Add stynglist',
      styngItem: {
        track: 'track: ',
      },
      create: {
        stynglistTitle: 'Stynglist title',
      },
      searchPlaceholder: 'Enter your styng title',
    },
    soundboard: {
      create: 'Create Soundboard',
      deleteAdTitle: 'Delete soundboard',
    },
    tracks: {
      tracks: 'Tracks',
      searchPlaceholder: 'Enter track title',
    },
    playlists: {
      create: {
        uploadPlaylist: 'Upload Playlist',
        fields: {
          name: 'Name',
        },
      },
      edit: {
        subsetSectionTitle: 'Allowed countries',
        blockedSubsetSectionTitle: 'Blocked countries',
        noSubset: 'There is no subset available for this list',
      },
    },
    users: {
      info: {
        email: 'Email',
        roles: 'Roles',
      },
    },
    app: {
      createApp: 'Create App',
      editApp: 'Edit App',
      linkedStynglist: 'Linked Stynglists',
      bindStynglist: 'Bind Stynglists',
      unsavedCancel: 'CANCEL - unsaved data will be lost',
      pricePlan: 'Price plan',
      prices: 'Prices',
      pricesAdded: 'Prices added',
      radioPlaylists: 'Radio playlists',
      generalInformation: 'General Information',
      features: 'Features',
    },
    appEvents: {
      newAppEvent: 'New App Event',
      create: {
        fields: {
          name: 'Name',
          description: 'Description',
          revenueShare: 'Revenue share',
          deductionShare: 'Deduction share',
          taxShare: 'Tax share',
          pricePerStream: 'Price per stream',
          price: 'Price',
          currency: 'Currency',
          productType: 'Product type',
          featureType: 'Feature type',
          availableStreamCount: 'Count',
          availableStreamMinutes: 'Minutes',
        },
      },
    },
    companies: {
      info: {
        applications: 'Applications',
      },
    },
    error: {
      header: 'Error !',
    },
    nfts: {
      areYouSureYouWantToMintThisNft: 'Are you sure you want to mint this NFT ?',
      areYouSureYouWantToContinue: 'Are you sure you want to continue?',
    },
    ad: {
      createAd: 'Create Ad',
      editAd: 'Edit Ad',
      uploadAd: 'Upload Ad',
      adError: 'Ad is required',
      supportedFiles: 'Supported files : AAC, MP3',
      countryOfAvailabilities: 'Country of availabilities of the ad',
      boundAdGroup: 'Bound to ad group within',
    },
  },
  header: {
    company: 'Companys',
    app: 'App',
    user: 'User',
  },
  navigation: {
    user: 'User',
    users: 'Users',
    createUser: 'Create user',
    editUser: 'Edit User',
    company: 'Company',
    companies: 'Companies',
    currency: 'Currency',
    currencies: 'Currencies',
    createCurrency: 'Create currency',
    editCurrency: 'Edit currency',
    apps: 'Apps',
    app: 'App',
    playlists: 'Playlists',
    createPlaylist: 'Add playlist',
    playlist: 'Playlist',
    styngs: 'Styngs',
    createStyng: 'Create styng',
    stynglists: 'Stynglists',
    createStynglist: 'Create stynglist',
    editStynglist: 'Edit stynglist',
    soundboard: 'Soundboard',
    createSoundboard: 'Create soundboard',
    editSoundboard: 'Edit soundboard',
    appEvents: 'App Events',
    createAppEvent: 'Create App Event',
    createApp: 'Create App',
    editApp: 'Edit App',
    createCompany: 'Create Company',
    editCompany: 'Edit Company',
    tracksForRadio: 'Tracks for Radio',
    tracksForStyngs: 'Tracks for Styngs',
    nfts: 'NFTs',
    radioAds: 'Radio Ads',
    radioAdGroups: 'Radio Ad Groups',
    reports: 'Reporting',
    activityLog: 'Activity Log',
    platforms: 'Platforms',
  },
  common: {
    loading: 'loading',
    signIn: 'Sign In',
    logout: 'Logout',
    cancel: 'Cancel',
    download: 'Download',
    save: 'Save',
    back: 'Back',
    delete: 'Delete',
    adminOn: 'Admin ON',
    adminOff: 'Admin OFF',
    search: 'Search',
    noFilters: 'No filters applied',
    errorLoading: 'Error Loading',
    noData: 'There are currently no data available for this list',
    create: 'Create',
    prepareForMinting: 'Prepare for minting',
    createNft: 'Create NFT',
    yes: 'YES',
    no: 'NO',
    id: 'ID',
    pricePlan: 'Price plan',
    prices: 'Prices',
    add: 'Add',
    fieldIsRequired: 'Field is required',
    ageRestriction: 'Age Restriction',
    generate: 'Generate',
  },
  fields: {
    name: 'Name',
    email: 'Email',
    password: 'Password',
    oldPassword: 'Old password',
    newPassword: 'New password',
    linkPayout: 'Link Payout',
    publickKey: 'Public Key',
    privateKey: 'Private Key',
    styngPrice: 'Styng Price',
    stynglistPrice: 'Stynglist Price',
    currency: 'Currency',
    company: 'Company',
    styngCurrency: 'Styng currency',
    stynglistCurrency: 'Stynglist currency',
    apiKey: 'API Key',
    firstName: 'First name',
    lastName: 'Last name',
    middleName: 'Middle name',
    genres: 'Genres',
    genre: 'Genre',
    artists: 'Artists',
    artist: 'Artist',
    recordLabels: 'recordLabels',
    label: 'Label',
    labelOwner: 'Label owner',
    labels: 'Labels',
    albums: 'Albums',
    album: 'Album',
    country: 'Country',
    countries: 'Countries',
    integrationType: 'Integration type',
    productTypes: 'Product types',
    isrc: 'ISRC',
    mediaNetID: 'MediaNet ID',
    inputPlaceholder: 'Start typing...',
    parentalAdvisory: 'Explicit Content',
    playlist: 'Playlist',
  },
  errors: {
    emailInvalid: 'The email is invalid.',
    password: 'Need more 5 character password',
    passwordInvalid: 'The password is invalid.',
    passwordConstraintsNotMet:
      'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character',
    incorrectValues: 'Incorrect values.',
    something: 'Something went wrong. Please repeat.',
    maxLength150: 'Max number of characters is 150',
    generalErrorMessage: 'Something went wrong.',
  },
  search: {
    emptyData: 'There are no results that match your search',
  },
  dmcModal: {
    title: 'DMCA compliance error',
    subTitle: 'Playlist name :',
    rulesAreMet: 'Playlist duration is DMCA compliant',
    noSongs: 'There are no songs in the playlist',
    rulesNotMetPartOne: 'This playlist`s minimum required duration is not DMCA compliant (3h)',
    rulesNotMetPartTwo: 'Please correct the following compliance issues :',
    countries: 'Non-compliant countries',
    playlistDuration: 'Playlist duration',
    timeToAdd: 'Missing minimum playlist duration',
  },
  jsonDataModal: {
    successText: 'Metadata successfully updated',
    enterMetadata: 'Enter metadata',
    errorTextKeyIsMissing: 'Make sure the “Key” field is populated, or delete any rows which are empty',
  },
  radioAds: {
    create: 'Create Ad',
    deleteAdTitle: 'Delete ad',
    deleteAd: `Deleting this ad will also remove it 
    from the associated ad group. Are you sure you want to delete this ad?`,
  },
  radioAdGroups: {
    create: 'Create Ad Group',
    edit: 'Edit Ad Group',
    adGroupForm: {
      adCadence: 'Ad cadence',
      firstAdBreak: 'First ad break',
      repeatableAdBreakOne: 'Repeatable ad break 1',
      repeatableAdBreakTwo: 'Repeatable ad break 2',
      numberOfTracks: 'Number of tracks before ad break',
      numberOfAds: 'Number of ads during ad break',
      adList: 'Ad list',
    },
  },
  platforms: {
    create: 'Create platform',
    edit: 'Edit platform',
  },
}
