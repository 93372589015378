import React, { useEffect } from 'react'
import * as R from 'ramda'
import { observer } from 'mobx-react'
import { useFormik } from 'formik'
import { useMutation, useQueryClient } from 'react-query'

import { useCreateImageMutation } from '../../common/hook/createImage'
import { defaultImg } from '../../common/constants'
import { ImageEntity } from '../../common/api/image/image'
import Text from '../../locale/strings'
import Button from '../../ui/Button/Button'
import useStores from '../../common/hook/useStore'
import TextField from '../../ui/TextField/TextField'
import API from '../../common/api'
import { EditCurrencyRequest, EditCurrencyEntity } from '../../common/api/currency/currency'
import { CurrenciesRoute } from '../../currency/pages'
import useImage from '../../common/hook/useImage'

import styles from './editCurrency.module.scss'

interface CurrencyFormValues {
  name: string
  imageId: Nullable<string>
}

const initialValues: CurrencyFormValues = {
  name: '',
  imageId: null,
}

interface EditCurrencyProps {
  initialCurrency?: EditCurrencyEntity
}

const EditCurrency = ({ initialCurrency }: EditCurrencyProps) => {
  const { navigationStore, applicationStore } = useStores()
  const queryClient = useQueryClient()

  const mutationCurrencyEvent = useMutation<any, Error, EditCurrencyRequest>(
    (body: EditCurrencyRequest) => {
      if (initialCurrency) {
        return API.currency.update(body)
      }

      return API.currency.create(body)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('currencies')
        navigationStore.goToPage(CurrenciesRoute.path)
      },
    },
  )

  const mutationImage = useCreateImageMutation((data: ImageEntity) => {
    formik.setFieldValue('imageId', data.id)
  })

  const handleSubmit = async (values: CurrencyFormValues) => {
    if (formik.dirty) {
      const body = {
        currencyId: initialCurrency?.id,
        name: values.name,
        imageId: values.imageId,
        appId: applicationStore.appId,
      }

      mutationCurrencyEvent.mutate(body)
    }
  }

  const formik = useFormik<CurrencyFormValues>({
    initialValues: initialValues,
    onSubmit: handleSubmit,
  })

  const { values, resetForm } = formik
  const { data: imageData } = useImage(values.imageId ?? '')
  const imageCurrency = imageData?.url ?? defaultImg

  useEffect(() => {
    if (!R.isNil(initialCurrency)) {
      resetForm({
        values: {
          name: initialCurrency.name,
          imageId: initialCurrency.imageId,
        },
      })
    }
  }, [initialCurrency, resetForm])

  const handleCancel = () => {
    navigationStore.goToPage(CurrenciesRoute.path)
  }

  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const file = event.target.files[0]

      if (file.type.includes('image/')) {
        const formData = new FormData()

        formData.append('file', file)

        mutationImage.mutate(formData)
      }
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.form}>
        <TextField label={Text.fields.name} name="name" value={values.name} onChange={formik.handleChange} />
        <div className={styles.uploadImage}>
          <img src={imageCurrency} alt="currencyImage" />
          <label htmlFor="file-image-id">
            <input hidden type="file" id="file-image-id" accept="image/*" value="" onChange={handleImageUpload} />
            <Button isUpload>{Text.page.styngs.create.uploadCover}</Button>
          </label>
        </div>
        <div className={styles.submitContainer}>
          <Button onClick={handleCancel}>{Text.common.cancel}</Button>
          <Button loading={mutationCurrencyEvent.isLoading} onClick={formik.handleSubmit}>
            {Text.common.save}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default observer(EditCurrency)
