import { useMutation, useQuery } from 'react-query'
import * as R from 'ramda'
import { thread } from 'lib/fn'

import API from '../common/api'
import { GenresResponse } from '../common/api/genre/genre'
import { ArtistsResponse } from '../common/api/artist/artist'
import { Album, toAlbum } from '../common/api/album/album'
import TracksStore from '../tracks/TracksStore'
import { Label, toLabel } from '../common/api/label/label'
import { LabelOwner, toLabelOwner } from '../common/api/labelOwner/labelOwner'

export interface ArtistsMutationProps {
  name: string
  limit?: number
}

export const useGenresQuery = () => {
  return useQuery<GenresResponse>(
    ['filter-genres'],
    () => {
      return API.track.filters.genres()
    },
    {
      keepPreviousData: true,
      retry: 1,
    },
  )
}

export const useArtistsMutation = () => {
  return useMutation<ArtistsResponse, any, ArtistsMutationProps>(
    ['filter-artists'],
    (body: ArtistsMutationProps) => {
      return API.track.filters.artists({
        name: body.name,
        limit: body.limit ?? 5,
      })
    },
    { retry: 1 },
  )
}

export const useAlbumsQuery = (trackStore: TracksStore, filter: string) => {
  const { activeArtists } = trackStore

  return useQuery<Album[]>(
    ['filter-album', filter, ...R.sort(R.subtract, R.map(R.prop('id'), activeArtists))],
    async () => {
      const arrRequest = R.map(
        (elem) =>
          API.track.filters.albums({
            title: filter,
            artistId: elem.id,
          }),
        activeArtists,
      )

      if (R.isEmpty(activeArtists)) {
        arrRequest.push(API.track.filters.albums({ title: filter }))
      }

      const results = await Promise.all(arrRequest)

      return thread(results, [R.map(R.prop('albums')), R.flatten, R.map(toAlbum), R.uniqWith(R.eqProps('id'))])
    },
    {
      keepPreviousData: true,
      retry: 1,
    },
  )
}

export const useLabelsQuery = (trackStore: TracksStore, filter: string) => {
  return useQuery<Label[]>(
    ['filter-label', filter],
    async () => {
      const labels = await API.track.filters.labels({
        name: filter,
        page: 1,
        size: 20,
      })

      return R.map(toLabel, labels.labels)
    },
    {
      keepPreviousData: true,
      retry: 1,
    },
  )
}

export const useLabelOwnersQuery = (trackStore: TracksStore, filter: string) => {
  return useQuery<LabelOwner[]>(
    ['filter-label-owners-radio', filter],
    async () => {
      const labelOwners = await API.track.filters.labelOwners({
        name: filter,
        page: 1,
        size: 20,
      })

      return R.map(toLabelOwner, labelOwners.labelOwners)
    },
    {
      keepPreviousData: true,
      retry: 1,
    },
  )
}
