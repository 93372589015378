/* eslint-disable jsx-control-statements/jsx-for-require-each */
import { useState, useEffect } from 'react'
import { QueryStatus, useMutation } from 'react-query'
import { Duration } from 'luxon'

import useStores from '../common/hook/useStore'
import { getCustomErrorNotificationByCode } from '../ui/Snackbar/SnackbarHelper'

import Box from '@mui/material/Box'
import Dialog from '@mui/material/Dialog'
import Table from '../ui/Table/Table'
import Pagination from '../ui/Pagination/Pagination'
import TrackFilter from './TrackFilter/TrackFilter'
import SearchBar from '../ui/SearchBar/SearchBar'
import LoadingRemote from '../common/LoadingRemote'
import { Nft, MintNft, TogglePublishNftRequest } from '../common/api/styng/styng'
import API from '../common/api'
import { truncateText } from 'common/utils'

import Button, { ButtonColor, ButtonVariant } from '../ui/Button/Button'

import Text from '../locale/strings'

import styles from './nfts.module.scss'

interface NftsProps {
  nfts: Nft[]
  status: QueryStatus
  pagesCount: number
  refetch: () => void
}

const Nfts = ({ nfts, pagesCount, status, refetch }: NftsProps) => {
  const { nftsStore, notificationsStore } = useStores()
  const [filter, setFilter] = useState<string>('')
  const [draftId, setDraftId] = useState<string>('')
  const [showModal, setShowModal] = useState<boolean>(false)
  const [showPublishModal, setShowPublishModal] = useState<boolean>(false)

  const [available, setAvailable] = useState<boolean>(false)

  //pagination check for every new render of Nfts page to start from 1
  useEffect(() => {
    return () => {
      nftsStore.changePage(1)
    }
  }, [])

  // Pagination logic
  const handleChangePage = (value: number) => {
    nftsStore.changePage(value)
  }

  const handleSearch = (value: string) => {
    nftsStore.search(value)
  }

  const mutationMintNft = useMutation<Nft, Error, MintNft>(
    (id) => {
      return API.nfts.mint(id)
    },
    {
      onSuccess: (data) => {
        notificationsStore.successNotification('Process successfully started')
        refetch()
        handleCloseModal()
      },
      onError: (error: any) => {
        handleCloseModal()

        const errorObj = JSON.stringify(error)
        const parseObj = JSON.parse(errorObj)
        const errorCode = parseObj.errorCode
        const errorMessage = getCustomErrorNotificationByCode(errorCode)

        notificationsStore.errorNotification(errorMessage)
      },
    },
  )

  const prepareForMintNft = (id: string) => {
    setDraftId(id)
    setShowModal(true)
  }

  const handleConfirm = (id: string) => {
    mutationMintNft.mutate({ id: id })
  }

  const handleConfirmPublishModal = (id: string) => {
    onPublishUnpublishMutation.mutate({ id: id })
  }

  const handleCloseModal = () => {
    setDraftId('')
    setShowModal(false)
  }

  const handleShowPublicModal = (id: string, active: string) => {
    const nft = nfts.filter((item) => item.id === id)

    setAvailable(!nft[0].published)
    setDraftId(id)
    setShowPublishModal(true)
  }

  const handleClosePublishModal = () => {
    setDraftId('')
    setAvailable(false)
    setShowPublishModal(false)
  }

  const onPublishUnpublishMutation = useMutation<boolean, Error, TogglePublishNftRequest>(
    (id) => {
      return API.nfts.publishUnpublish(id)
    },
    {
      onSuccess: () => {
        refetch()
        handleClosePublishModal()

        available
          ? notificationsStore.successNotification('NFT successfully published')
          : notificationsStore.successNotification('NFT successfully unpublished')
      },
      onError: (error: any) => {
        const errorObj = JSON.stringify(error)
        const parseObj = JSON.parse(errorObj)
        const errorCode = parseObj.errorCode
        const errorMessage = getCustomErrorNotificationByCode(errorCode)

        notificationsStore.errorNotification(errorMessage)
        handleClosePublishModal()
      },
    },
  )

  // Table data
  const columns = [
    'Cover',
    'Id',
    'Name',
    'Album',
    'Artist',
    'Song',
    'Record label',
    'Duration',
    'Genre',
    'Application',
    'Status',
    'Published',
    'Actions',
  ]

  const dataTable = nfts.map((nft: Nft) => [
    nft.imageUrl,
    nft.id,
    truncateText(nft.nftName),
    nft.album,
    nft.artist,
    nft.trackTitle,
    nft.label,
    Duration.fromISO(nft.duration).toFormat('mm:ss'),
    nft.genre,
    nft.application,
    nft.status,
    !nft.published ? 'false' : 'true',
  ])

  return (
    <div className={styles.container}>
      <div className={styles.disabled}>
        <div className={styles.header}>
          <SearchBar
            hideResults
            label={Text.page.tracks.searchPlaceholder}
            filter={filter}
            onChangeFilter={setFilter}
            onSearch={handleSearch}
          />
        </div>
        <TrackFilter tracksStore={nftsStore} />
      </div>
      <LoadingRemote status={status}>
        {nfts.length > 0 ? (
          <Table
            disabledDelete
            disabledEdit
            mintButton
            publishedToggle
            disabledToggle
            hideColData
            hiddenColumns={['Id']}
            columns={columns}
            data={dataTable}
            prepareForMintNft={prepareForMintNft}
            handleActivate={handleShowPublicModal}
          />
        ) : (
          <p>There are no results that match your search</p>
        )}
      </LoadingRemote>

      <If condition={pagesCount > 1}>
        <div className={styles.paginationContainer}>
          <div className={styles.pagination}>
            <Pagination page={nftsStore.page} count={pagesCount} onChange={handleChangePage} />
          </div>
        </div>
      </If>

      {showModal && (
        <Dialog open={true} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
          <Box>
            <div className={styles.confirmationModal}>
              <div className={styles.header}>{'Confirm'}</div>
              <p>{Text.page.nfts.areYouSureYouWantToMintThisNft}</p>
              <div className={styles.submitContainer}>
                <Button variant={ButtonVariant.OUTLINED} onClick={() => handleCloseModal()}>
                  {Text.common.no}
                </Button>
                <Button color={ButtonColor.PRIMARY} onClick={() => handleConfirm(draftId)}>
                  {Text.common.yes}
                </Button>
              </div>
            </div>
          </Box>
        </Dialog>
      )}
      {showPublishModal && (
        <Dialog
          open={true}
          maxWidth="xs"
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <Box>
            <div className={styles.publishModal}>
              <div className={styles.header}>{available ? 'Publish NFT' : 'Unpublish NFT'}</div>
              {available ? (
                <p className={styles.publishQue}>
                  By publishing NFT, you will make it <span style={{ color: 'green' }}>{'available'}</span> to app
                  developers via SDK.
                </p>
              ) : (
                <p className={styles.publishQue}>
                  By unpublishing NFT, you will make it <span style={{ color: 'red' }}>{'unavailable'}</span> to app
                  developers via SDK.
                </p>
              )}
              <p style={{ fontWeight: 'bold' }}>{Text.page.nfts.areYouSureYouWantToContinue}</p>
              <div className={styles.submitContainer}>
                <Button variant={ButtonVariant.OUTLINED} onClick={() => handleClosePublishModal()}>
                  {Text.common.no}
                </Button>
                <Button
                  loading={onPublishUnpublishMutation.isLoading}
                  color={ButtonColor.PRIMARY}
                  onClick={() => handleConfirmPublishModal(draftId)}
                >
                  {Text.common.yes}
                </Button>
              </div>
            </div>
          </Box>
        </Dialog>
      )}
    </div>
  )
}

export default Nfts
