import { useMutation } from 'react-query'

import API from '../../common/api'
import useStores from '../../common/hook/useStore'
import { getCustomErrorNotificationByCode } from '../../ui/Snackbar/SnackbarHelper'

import { ImageEntity } from '../../common/api/image/image'

export const useCreateImageMutation = (onSuccess: any) => {
  const { notificationsStore } = useStores()

  return useMutation<ImageEntity, Error, FormData>(
    (body: FormData) => {
      return API.image.create(body)
    },
    {
      onSuccess: (data) => {
        if (onSuccess) {
          onSuccess(data)
        }
      },
      onError: (error) => {
        const errorObj = JSON.stringify(error)
        const parseObj = JSON.parse(errorObj)
        const errorCode = parseObj.errorCode
        const errorMessage = getCustomErrorNotificationByCode(errorCode)

        notificationsStore.errorNotification(errorMessage)
      },
    },
  )
}
