import { observer } from 'mobx-react'

import PlatformsStore, { usePlatforsmListQuery } from '../../PlatformsStore'

import useStores from 'common/hook/useStore'
import { Platform } from 'common/api/platforms/platforms'

import Spinner from 'ui/Spinner/Spinner'
import Table from 'ui/Table/Table'
import Button from 'ui/Button/Button'

import Text from 'locale/strings'

import { PlatformCreateRoute, PlatformEditRoute } from '../..'

import { truncateText } from 'common/utils'
import { ApiStatuses } from 'common/constants'

import styles from './PlatformsTable.module.scss'

interface PlatformsTableProps {
  platformsStore: PlatformsStore
}

const PlatformsTable = ({ platformsStore }: PlatformsTableProps) => {
  const { navigationStore } = useStores()
  const { data, status } = usePlatforsmListQuery(platformsStore)
  const platforms = data ?? []

  const columns = ['Id', 'Name', 'Prefix', 'Actions']

  const dataTable = platforms.map((platform: Platform) => [
    platform.id,
    truncateText(platform.name),
    platform.offerCodePrefix,
  ])

  const handleCreate = () => {
    navigationStore.goToPage(PlatformCreateRoute.path)
  }

  const handleEditClick = (id: string) => {
    navigationStore.goToPage(PlatformEditRoute.getUrl({ id: id }))
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Button data-test="create-button" onClick={() => handleCreate()}>
          {Text.platforms.create}
        </Button>
      </div>
      <div className={styles.tableWrapper}>
        {status === ApiStatuses.LOADING ? (
          <Spinner />
        ) : (
          <Table hideDelete columns={columns} data={dataTable} onEditClick={handleEditClick} />
        )}
      </div>
    </div>
  )
}

export default observer(PlatformsTable)
