import { useQuery, useInfiniteQuery } from 'react-query'
import * as R from 'ramda'

import { AdminMode } from '../common/constants'
import { action, observable } from 'mobx'

import PaginationStore from 'common/PaginationStore'
import { AppInfoEntity, AppsResponse } from '../common/api/app/app'
import { AppPlaylistsResponse, AppPlaylistsRequest } from '../common/api/playlist/playlist'

import API from '../common/api'
import useStores from '../common/hook/useStore'
import Storage from '../common/storage'

class ApplicationStore extends PaginationStore {
  @observable
  name: string = ''

  @observable
  companyId = ''

  @observable
  appId: string = ''

  @observable
  stinglistId: string = ''

  @observable
  userId: string = ''

  @observable
  adminMode: AdminMode = AdminMode.OFF

  @action
  handleNameSearch = (filter: string) => {
    this.name = filter?.trim()
    this.changePage()
  }

  @action
  handleIdSearch = (filter: string) => {
    this.appId = filter?.trim()
    this.changePage()
  }

  @action
  setCompanyId = (companyId: string) => {
    this.companyId = companyId?.trim()
  }

  @action
  setAppId = (appId: string) => {
    this.appId = appId?.trim()
  }

  @action
  setStinglistId = (stinglistId: string) => {
    this.stinglistId = stinglistId
  }

  @action
  setUserId = (userId: string) => {
    this.userId = userId
  }

  @action
  setAdminMode = (value: AdminMode) => {
    this.adminMode = value
    Storage.set('adminMode', value)
  }
}

export const useLoadMoreAppsQuery = () => {
  return useInfiniteQuery<AppsResponse>(
    ['load-more-apps'],
    async ({ pageParam = 1 }) => {
      const response = await API.app.list({
        page: pageParam,
        size: 30,
      })

      return response
    },
    {
      getNextPageParam: (lastPage, pages) => {
        if (pages.length === lastPage.pageCount) {
          return
        }

        return pages.length + 1
      },
    },
  )
}

export const useAppsQuery = (filterStore: ApplicationStore) => {
  const { pageSize, page, name, appId } = filterStore

  return useQuery<AppsResponse>(
    ['apps', pageSize, page, name, appId],
    () => {
      return API.app.list({
        page: page,
        size: pageSize,
        name,
        appId,
      })
    },
    {
      keepPreviousData: false,
      retry: 1,
      refetchOnWindowFocus: false,
    },
  )
}

export const useAppQuery = (appParam: Nullable<string> = null) => {
  const { applicationStore } = useStores()
  const { appId: appStore } = applicationStore
  const appId = appParam ?? appStore

  return useQuery<AppInfoEntity>(
    ['app', appId],
    () => {
      return API.app.get({
        id: appId,
      })
    },
    {
      enabled: !R.isEmpty(appId),
      keepPreviousData: true,
      retry: 1,
    },
  )
}

export const useAppPlaylistsQuery = (appId: string) => {
  return useQuery<AppPlaylistsResponse, AppPlaylistsRequest>(
    [`${appId}-appPlaylists`, appId],
    () => {
      return API.app.getAppPlaylists({ appId: appId })
    },
    {
      retry: 1,
      keepPreviousData: false,
    },
  )
}

export const useFeaturesQuery = () => {
  return useQuery(
    ['features'],
    () => {
      return API.app.getAppFeatures()
    },
    {
      retry: 1,
      keepPreviousData: false,
    },
  )
}

export default ApplicationStore
