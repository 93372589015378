import React, { useEffect, useState, useRef } from 'react'
import * as R from 'ramda'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { useMutation, useQueryClient } from 'react-query'
import { ApiError } from 'lib/api/error'

import { useCreateImageMutation } from '../../common/hook/createImage'
import useStores from '../../common/hook/useStore'
import { getCustomErrorNotificationByCode } from '../../ui/Snackbar/SnackbarHelper'

import Modal from '@mui/material/Modal'
import Box from '@mui/material/Box'

import Spinner from '../../ui/Spinner/Spinner'
import Button, { ButtonColor, ButtonVariant } from '../../ui/Button/Button'
import SearchBar from '../../ui/SearchBar/SearchBar'
import TextField from '../../ui/TextField/TextField'

import Text from '../../locale/strings'

import API from '../../common/api'
import TrackItem from '../CreateStyng/TrackItem/Trackitem'
import CreateStyngStore from '../CreateStyng/CreateStyngStore'
import { useStyngQuery } from '../../styngs/CreateStyng/CreateStyngStore'
import { EditStyngRequest, EditStyngResponse, GetStyngRequest } from '../../common/api/styng/styng'
import { ImageEntity, ImageUrl } from '../../common/api/image/image'
import { Track } from '../../common/api/track/track'
import { defaultImg } from '../../common/constants'
import useImage from '../../common/hook/useImage'
import LsrTracksFilter from '../CreateStyng/LsrTracksFilter'

import styles from './styngModal.module.scss'

const schema = yup.object({
  title: yup.string().max(50, 'Title must be at most 50 characters').required('Title is required'),
  genre: yup.string().required('Genre is required'),
})

interface StyngFormValues {
  title: string
  genre: string
  imageId: Nullable<string>
}

const initialValues: StyngFormValues = {
  title: '',
  genre: '',
  imageId: null,
}

interface StyngProps {
  editStyngStore: CreateStyngStore
  styngId: string
  open: boolean
  handleClose: () => void
}

const StyngModal = ({ editStyngStore, open, styngId, handleClose }: StyngProps) => {
  const { notificationsStore } = useStores()
  const [renderTracks, setRenderTracks] = useState<boolean>(false)
  const [showActiveTrack, setShowActiveTrack] = useState<boolean>(false)
  const [styngError, setStyngError] = useState<boolean>(false)
  const [styngFormatError, setStyngFormatError] = useState<boolean>(false)
  const [filter, setFilter] = useState('')
  const [url, setUrl] = useState('')
  const [styng, setStyng] = useState<Nullable<File>>(null)
  const queryClient = useQueryClient()
  const { data: initialStyng } = useStyngQuery(styngId)
  const audioRef = useRef<any>(null)

  const addNewSting = styngId.length === 0

  const mutationStyng = useMutation<EditStyngResponse, Error, EditStyngRequest>(
    (body: EditStyngRequest) => {
      if (!R.isNil(initialStyng)) {
        return API.styngs.put({ ...body, id: initialStyng.id })
      }

      return API.styngs.create(body)
    },
    {
      onSuccess: () => {
        editStyngStore.setTrack(null)
        handleClose()
        queryClient.invalidateQueries('styngs')

        !R.isNil(initialStyng)
          ? notificationsStore.successNotification('Styng successfully edited')
          : notificationsStore.successNotification('Styng successfully added')
      },
    },
  )

  const mutationImage = useCreateImageMutation((data: ImageEntity) => {
    formik.setFieldValue('imageId', data.id)
  })

  const mutationImageUrl = useMutation<ImageEntity, Error, ImageUrl>((body: ImageUrl) => {
    return API.image.postUrl(body)
  })

  const handleSubmit = async (values: StyngFormValues) => {
    if (R.isNil(styng)) {
      setStyngError(true)
    } else {
      setStyngError(false)
    }

    const sendForm = (imageId: Nullable<string>) => {
      let uploadId: string
      let uploadUrl: string

      if (R.isNil(styng)) {
        mutationStyng.mutate({
          title: values.title.trim(),
          genre: values.genre.trim(),
          trackId: editStyngStore?.currentTrack?.trackId ?? initialStyng?.trackId,
          imageId: imageId,
          fileId: '',
        })
      } else {
        const getDataForStyngUpload = API.styngs.getUploadData()

        getDataForStyngUpload
          .then((response) => {
            uploadId = response.id
            uploadUrl = response.uploadUrl

            return fetch(uploadUrl, {
              method: 'PUT',
              body: styng,
            })
          })
          .then(() => {
            mutationStyng.mutate({
              title: values.title.trim(),
              genre: values.genre.trim(),
              trackId: editStyngStore?.currentTrack?.trackId ?? initialStyng?.trackId,
              imageId: imageId,
              fileId: uploadId,
            })
          })
      }
    }

    const isFormValid = (!R.isNil(editStyngStore.currentTrack) && !R.isNil(styng)) || !R.isNil(initialStyng)

    if (isFormValid) {
      if (!R.isNil(values.imageId)) {
        sendForm(values.imageId)

        return
      }

      if (editStyngStore.currentTrack?.imageUrl) {
        try {
          await mutationImageUrl.mutate(
            { url: editStyngStore.currentTrack.imageUrl },
            {
              onSuccess: (data) => {
                sendForm(data.id)
              },
            },
          )
        } catch (error: any) {
          const errorObj = JSON.stringify(error)
          const parseObj = JSON.parse(errorObj)
          const errorCode = parseObj.errorCode
          const errorMessage = getCustomErrorNotificationByCode(errorCode)

          notificationsStore.errorNotification(errorMessage)
        }
      }
    }
  }

  const handleStyngUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const file = event.target.files[0]

      if (file.type === 'audio/mpeg' || file.type === 'audio/vnd.dlna.adts') {
        setStyng(file)
        setStyngError(false)
      } else {
        setStyngFormatError(true)

        setTimeout(() => setStyngFormatError(false), 3000)
      }
    }
  }

  useEffect(() => {
    if (!R.isNil(styng)) {
      const url = URL.createObjectURL(styng)

      setUrl(url)

      if (audioRef.current) {
        audioRef.current.load()
      }
    }
  }, [styng])

  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const file = event.target.files[0]

      if (file.type.includes('image/')) {
        const formData = new FormData()

        formData.append('file', file)

        mutationImage.mutate(formData)
      }
    }
  }

  const mutationGetStyng = useMutation<string, ApiError, GetStyngRequest>(
    async (body: GetStyngRequest) => {
      return API.track.getStyngUrl(body)
    },
    {
      onSuccess: (data) => {
        setUrl(data)
      },
      onError: () => {
        notificationsStore.errorNotification('Styng is unavailable for reproduction')
      },
    },
  )

  const formik = useFormik<StyngFormValues>({
    initialValues: initialValues,
    validationSchema: schema,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: handleSubmit,
  })

  const { values, resetForm, setFieldValue } = formik
  const { data } = useImage(values.imageId || '')
  const imageStyng = data?.url ?? editStyngStore?.currentTrack?.imageUrl ?? defaultImg

  useEffect(() => {
    setFieldValue('genre', editStyngStore.currentTrack?.genre ?? '')
    setFieldValue('imageId', null)
  }, [editStyngStore.currentTrack, setFieldValue])

  useEffect(() => {
    if (!R.isNil(initialStyng)) {
      resetForm({
        values: {
          title: initialStyng.name,
          genre: initialStyng.genres[0]?.name,
          imageId: initialStyng.imageId,
        },
      })
    }
  }, [initialStyng, resetForm])

  useEffect(() => {
    if (styngId !== '') {
      mutationGetStyng.mutate({ id: styngId })
    }
  }, [])

  const handleClickTrack = (track: Track) => {
    editStyngStore.setTrack(track)
    editStyngStore.search('')
    setFilter('')
    setRenderTracks(false)

    if (!R.isEmpty(editStyngStore.currentTrack)) {
      setShowActiveTrack(true)
    } else {
      setShowActiveTrack(false)
    }

    setTimeout(() => formik.validateField('genre'))
  }

  useEffect(() => {
    if (editStyngStore.currentTrack !== null) {
      setShowActiveTrack(true)
      setFieldValue('genre', editStyngStore.currentTrack?.genre ?? '')
      setFieldValue('imageId', null)
    }
  }, [editStyngStore.currentTrack, setFieldValue])

  const handleSearch = (value: string) => {
    editStyngStore.search(value)

    if (!R.isEmpty(editStyngStore.filter)) {
      setRenderTracks(true)
    } else {
      setRenderTracks(false)
    }
  }

  return (
    <Modal open={open} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <Box>
        {styngId && R.isNil(initialStyng) ? (
          <Spinner />
        ) : (
          <div className={styles.container}>
            <div className={styles.header}>{addNewSting ? 'Add styng' : 'Edit styng'}</div>
            <div className={styles.innerContainer}>
              {!addNewSting && (
                <div className={styles.searchWrapper}>
                  <SearchBar
                    modalView
                    label={Text.page.tracks.searchPlaceholder}
                    filter={filter}
                    onChangeFilter={setFilter}
                    onSearch={handleSearch}
                  >
                    <If condition={renderTracks}>
                      <LsrTracksFilter editStyngStore={editStyngStore} onClickTrack={handleClickTrack} />
                    </If>
                  </SearchBar>
                </div>
              )}
              <div className={styles.form}>
                <If condition={showActiveTrack && open}>
                  <TrackItem active track={editStyngStore.currentTrack!} onClick={handleClickTrack} />
                </If>
                <div className={styles.uploadCover}>
                  <label htmlFor="file-styng-id">
                    <input
                      hidden
                      id="file-styng-id"
                      type="file"
                      accept="audio/aac, audio/mp3"
                      value=""
                      onChange={handleStyngUpload}
                    />
                    <Button isUpload data-test="upload-styng-button">
                      {Text.page.styngs.create.uploadStyng}
                    </Button>
                    <If condition={styngError && R.isNil(initialStyng)}>
                      <span className={styles.customError}>{Text.page.styngs.styngError}</span>
                    </If>
                    <If condition={styngFormatError}>
                      <span className={styles.customError}>{Text.page.styngs.supportedFiles}</span>
                    </If>
                  </label>
                  <If condition={!R.isNil(styng)}>
                    <span className={styles.fileName}>{styng?.name}</span>
                  </If>
                </div>
                {url && (
                  <audio controls controlsList="nodownload" ref={audioRef} className={styles.audioPreview}>
                    <source src={url} />
                  </audio>
                )}
                <TextField
                  fullWidth
                  data-test="title-field"
                  label={Text.page.styngs.create.fields.title}
                  name="title"
                  value={values.title}
                  error={formik.errors.title}
                  onChange={formik.handleChange}
                />
                <TextField
                  fullWidth
                  data-test="genre-field"
                  label={Text.page.styngs.create.fields.genre}
                  name="genre"
                  value={values.genre}
                  disabled={true}
                  className={styles.input}
                  error={formik.errors.genre}
                  onChange={formik.handleChange}
                />
                <div className={styles.uploadImage}>
                  <img src={imageStyng} alt="styngImage" />
                  <label htmlFor="file-image-id">
                    <input
                      hidden
                      id="file-image-id"
                      type="file"
                      accept="image/*"
                      value=""
                      onChange={handleImageUpload}
                    />
                    <Button isUpload data-test="upload-image-button">
                      {Text.page.styngs.create.uploadCover}
                    </Button>
                  </label>
                </div>
              </div>
            </div>

            <div className={styles.submitContainer}>
              <Button data-test="cancel-button" variant={ButtonVariant.OUTLINED} onClick={handleClose}>
                {Text.common.cancel}
              </Button>
              <Button
                data-test="submit-button"
                disabled={!(formik.isValid && formik.dirty) && styng === null}
                loading={mutationStyng.isLoading}
                color={!(formik.isValid && formik.dirty) && styng === null ? ButtonColor.INFO : ButtonColor.PRIMARY}
                onClick={formik.handleSubmit}
              >
                {Text.common.save}
              </Button>
            </div>
          </div>
        )}
      </Box>
    </Modal>
  )
}

export default StyngModal
