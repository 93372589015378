/* eslint-disable jsx-control-statements/jsx-for-require-each */
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useMutation } from 'react-query'
import { observer } from 'mobx-react'
import { useFormik } from 'formik'
import * as yup from 'yup'
import * as R from 'ramda'

import useStores from '../../../common/hook/useStore'
import { getCustomErrorNotificationByCode } from '../../../ui/Snackbar/SnackbarHelper'

import { useListPlaylistsWithoutAdGroupQuery } from '../../../playlists/PlaylistsStore'

import { RadioAdGroupsRoute } from '../..'
import { useAdGroupQuery } from '../../hooks'

import AdItemWithMoreInfo from '../../AdItemWithMoreInfo/AdItemWithMoreInfo'

import { Toolbar, Typography, Box } from '@mui/material'

import Spinner from '../../../ui/Spinner/Spinner'
import Text from '../../../locale/strings'
import TextField from '../../../ui/TextField/TextField'
import Select from '../../../ui/Select/Select'
import Button, { ButtonVariant } from '../../../ui/Button/Button'

import { ApiStatuses } from '../../../common/constants'
import API from '../../../common/api'
import { OptionType } from '../../../common/api/common/common'
import { Ad, AdBlockData, RadioAdGroupRequest } from '../../../common/api/radioAdGroups/radioAdGroups'

import styles from './RadioAdGroup.module.scss'

const schema = yup.object().shape({
  playlistId: yup.string().required('Playlist is required'),
  initialAdBlock: yup.object().shape({
    numberOfTracksBeforeAdBlock: yup
      .number()
      .typeError('Must be a number')
      .min(0, 'Field value must be greater than or equal to 0')
      .required('Field is required'),
    adCountDuringAdBlock: yup
      .number()
      .typeError('Must be a number')
      .min(0, 'Field value must be greater than or equal to 0')
      .required('Field is required'),
  }),
  repeatableAdBlockOne: yup.object().shape({
    numberOfTracksBeforeAdBlock: yup
      .number()
      .typeError('Must be a number')
      .min(0, 'Field value must be greater than or equal to 0')
      .required('Field is required')
      .test(
        '',
        `Set value greater than 0 in at 
        least one repeatable ad block.`,
        (value, schema) => {
          if (typeof value === 'undefined') {
            return true
          }

          const options: any = schema.options

          const repeatableAdBlockTwoNumberOfTracks =
            options.from[1].value.repeatableAdBlockTwo.numberOfTracksBeforeAdBlock

          if (value === 0 && repeatableAdBlockTwoNumberOfTracks === 0) {
            return false
          }

          return true
        },
      ),
    adCountDuringAdBlock: yup
      .number()
      .typeError('Must be a number')
      .min(0, 'Field value must be greater than or equal to 0')
      .required('Field is required'),
  }),
  repeatableAdBlockTwo: yup.object().shape({
    numberOfTracksBeforeAdBlock: yup
      .number()
      .typeError('Must be a number')
      .min(0, 'Field value must be greater than or equal to 0')
      .required('Field is required')
      .test(
        '',
        `Set value greater than 0 in at 
      least one repeatable ad block.`,
        (value, schema) => {
          if (typeof value === 'undefined') {
            return true
          }

          const options: any = schema.options

          const repeatableAdBlockTwoNumberOfTracks =
            options.from[1].value.repeatableAdBlockOne.numberOfTracksBeforeAdBlock

          if (value === 0 && repeatableAdBlockTwoNumberOfTracks === 0) {
            return false
          }

          return true
        },
      ),
    adCountDuringAdBlock: yup
      .number()
      .typeError('Must be a number')
      .min(0, 'Field value must be greater than or equal to 0')
      .required('Field is required'),
  }),
})

interface ParamProps {
  playlistId?: string
}

interface AdGroup {
  playlistId: string
  initialAdBlock: AdBlockData
  repeatableAdBlockOne: AdBlockData
  repeatableAdBlockTwo: AdBlockData
}

const RadioAdGroup = () => {
  const { navigationStore, notificationsStore } = useStores()
  const [playlistOptions, setPlaylistOptions] = useState<OptionType[]>([])
  const { playlistId = '' } = useParams<ParamProps>()
  const { data: adGroup, refetch: adGroupRefetch } = useAdGroupQuery(playlistId)
  const { data: availablePlaylists, status: availablePlaylistsStatus } = useListPlaylistsWithoutAdGroupQuery()

  const [initialValues, setInitialValues] = useState({
    playlistId: '',
    initialAdBlock: {
      numberOfTracksBeforeAdBlock: '',
      adCountDuringAdBlock: '',
    },
    repeatableAdBlockOne: {
      numberOfTracksBeforeAdBlock: '',
      adCountDuringAdBlock: '',
    },
    repeatableAdBlockTwo: {
      numberOfTracksBeforeAdBlock: '',
      adCountDuringAdBlock: '',
    },
  })

  const initialRadioAdGroup = playlistId !== '' ? adGroup : null

  const mutationAdGroup = useMutation<any, Error, RadioAdGroupRequest>(
    (body: RadioAdGroupRequest) => {
      if (!R.isNil(initialRadioAdGroup)) {
        return API.playlist.editAdGroup(body)
      }

      return API.playlist.createAdGroup(body)
    },
    {
      onSuccess: () => {
        resetForm()
        navigationStore.goToPage(RadioAdGroupsRoute.path)

        !R.isNil(initialRadioAdGroup)
          ? notificationsStore.successNotification('Ad Group successfully edited')
          : notificationsStore.successNotification('Ad Group successfully added')
      },
      onError: (error: any) => {
        const errorObj = JSON.stringify(error)
        const parseObj = JSON.parse(errorObj)
        const errorCode = parseObj.errorCode
        const errorMessage = getCustomErrorNotificationByCode(errorCode)

        notificationsStore.errorNotification(errorMessage)
      },
    },
  )

  const onDeleteMutation = useMutation(
    (body: any) => {
      return API.radioAds.deleteAd({ adId: body.id })
    },
    {
      onSuccess: () => {
        notificationsStore.successNotification('Ad successfully deleted')
        adGroupRefetch()
      },
      onError: (error: any) => {
        const errorObj = JSON.stringify(error)
        const parseObj = JSON.parse(errorObj)
        const errorCode = parseObj.errorCode
        const errorMessage = getCustomErrorNotificationByCode(errorCode)

        notificationsStore.errorNotification(errorMessage)
      },
    },
  )

  useEffect(() => {
    if (!R.isNil(initialRadioAdGroup)) {
      setInitialValues(adGroup)
    }
  }, [adGroup])

  useEffect(() => {
    const result = availablePlaylists?.playlists ?? []

    const formatedResult = result.map((playlist: any) => {
      return {
        label: playlist.name,
        value: playlist.id,
      }
    })

    setPlaylistOptions(formatedResult)
  }, [availablePlaylists])

  const handleSubmit = async (values: AdGroup) => {
    const newValues = {
      playlistId: values.playlistId,
      initialAdBlock: values.initialAdBlock,
      repeatableAdBlockOne: values.repeatableAdBlockOne,
      repeatableAdBlockTwo: values.repeatableAdBlockTwo,
    }

    mutationAdGroup.mutate(newValues)
  }

  const formik = useFormik<AdGroup>({
    initialValues: initialValues,
    validationSchema: schema,
    validateOnBlur: false,
    validateOnChange: false,
    enableReinitialize: true,
    onSubmit: handleSubmit,
  })

  const { values, resetForm, setFieldValue } = formik

  const handleChangePlaylist = (value: string) => {
    setFieldValue('playlistId', value)
  }

  const handleDeleteClick = (id: string) => {
    onDeleteMutation.mutate({ id: id })
  }

  return (
    <React.Fragment>
      {playlistId !== '' && R.isNil(initialRadioAdGroup) ? (
        <Spinner />
      ) : (
        <Box>
          {availablePlaylistsStatus === ApiStatuses.LOADING ? (
            <Spinner />
          ) : (
            <React.Fragment>
              <Toolbar className={styles.toolbar}>
                <Typography sx={{ ml: 2, flex: 1, color: '#fff' }} variant="h6" component="div">
                  {R.isNil(initialRadioAdGroup) ? Text.radioAdGroups.create : Text.radioAdGroups.edit}
                </Typography>
              </Toolbar>
              <div className={styles.container}>
                <div className={styles.form}>
                  {R.isNil(initialRadioAdGroup) ? (
                    <Select
                      data-test="playlist-field"
                      className={styles.customSelect}
                      label={Text.fields.playlist + ' *'}
                      name="playlistId"
                      value={values.playlistId}
                      options={playlistOptions}
                      error={formik.errors.playlistId}
                      onChange={handleChangePlaylist}
                    />
                  ) : (
                    <TextField
                      disabled
                      data-test="playlist-field"
                      className={styles.customSelect}
                      label={Text.fields.playlist + ' *'}
                      name="playlistId"
                      value={adGroup.playlistName}
                    />
                  )}
                  <p className={styles.sectionHeader}>{Text.radioAdGroups.adGroupForm.adCadence}</p>
                  <div className={styles.ruleRow}>
                    <p className={styles.sectionName}>{Text.radioAdGroups.adGroupForm.firstAdBreak}</p>
                    <div className={styles.customTextFieldWrapper}>
                      <TextField
                        data-test="number-of-tracks-field"
                        type="number"
                        className={styles.customTextField}
                        label={Text.radioAdGroups.adGroupForm.numberOfTracks + ' *'}
                        name="initialAdBlock.numberOfTracksBeforeAdBlock"
                        value={values.initialAdBlock.numberOfTracksBeforeAdBlock}
                        error={formik.errors.initialAdBlock?.numberOfTracksBeforeAdBlock}
                        InputProps={{
                          inputProps: {
                            min: 0,
                          },
                        }}
                        onChange={formik.handleChange}
                      />
                      <TextField
                        data-test="number-of-ads-field"
                        type="number"
                        className={styles.customTextField}
                        label={Text.radioAdGroups.adGroupForm.numberOfAds + ' *'}
                        name="initialAdBlock.adCountDuringAdBlock"
                        value={values.initialAdBlock.adCountDuringAdBlock}
                        error={formik.errors.initialAdBlock?.adCountDuringAdBlock}
                        InputProps={{
                          inputProps: {
                            min: 0,
                          },
                        }}
                        onChange={formik.handleChange}
                      />
                    </div>
                  </div>
                  <div className={styles.ruleRow}>
                    <p className={styles.sectionName}>{Text.radioAdGroups.adGroupForm.repeatableAdBreakOne}</p>
                    <div className={styles.customTextFieldWrapper}>
                      <TextField
                        data-test="number-of-tracks-field"
                        type="number"
                        className={styles.customTextField}
                        label={Text.radioAdGroups.adGroupForm.numberOfTracks + ' *'}
                        name="repeatableAdBlockOne.numberOfTracksBeforeAdBlock"
                        value={values.repeatableAdBlockOne.numberOfTracksBeforeAdBlock}
                        error={formik.errors.repeatableAdBlockOne?.numberOfTracksBeforeAdBlock}
                        InputProps={{
                          inputProps: {
                            min: 0,
                          },
                        }}
                        onChange={formik.handleChange}
                      />
                      <TextField
                        data-test="number-of-ads-field"
                        type="number"
                        className={styles.customTextField}
                        label={Text.radioAdGroups.adGroupForm.numberOfAds + ' *'}
                        name="repeatableAdBlockOne.adCountDuringAdBlock"
                        value={values.repeatableAdBlockOne.adCountDuringAdBlock}
                        error={formik.errors.repeatableAdBlockOne?.adCountDuringAdBlock}
                        InputProps={{
                          inputProps: {
                            min: 0,
                          },
                        }}
                        onChange={formik.handleChange}
                      />
                    </div>
                  </div>
                  <div className={styles.ruleRow}>
                    <p className={styles.sectionName}>{Text.radioAdGroups.adGroupForm.repeatableAdBreakTwo}</p>
                    <div className={styles.customTextFieldWrapper}>
                      <TextField
                        data-test="number-of-tracks-field"
                        type="number"
                        className={styles.customTextField}
                        label={Text.radioAdGroups.adGroupForm.numberOfTracks + ' *'}
                        name="repeatableAdBlockTwo.numberOfTracksBeforeAdBlock"
                        value={values.repeatableAdBlockTwo.numberOfTracksBeforeAdBlock}
                        error={formik.errors.repeatableAdBlockTwo?.numberOfTracksBeforeAdBlock}
                        InputProps={{
                          inputProps: {
                            min: 0,
                          },
                        }}
                        onChange={formik.handleChange}
                      />
                      <TextField
                        data-test="number-of-ads-field"
                        type="number"
                        className={styles.customTextField}
                        label={Text.radioAdGroups.adGroupForm.numberOfAds + ' *'}
                        name="repeatableAdBlockTwo.adCountDuringAdBlock"
                        value={values.repeatableAdBlockTwo.adCountDuringAdBlock}
                        error={formik.errors.repeatableAdBlockTwo?.adCountDuringAdBlock}
                        InputProps={{
                          inputProps: {
                            min: 0,
                          },
                        }}
                        onChange={formik.handleChange}
                      />
                    </div>
                  </div>
                  <p className={styles.sectionHeader}>{Text.radioAdGroups.adGroupForm.adList}</p>
                  <div className={styles.adColumn}>
                    {adGroup?.ads.length !== 0 && !R.isNil(initialRadioAdGroup) ? (
                      adGroup?.ads.map((ad: Ad) => {
                        return (
                          <AdItemWithMoreInfo
                            key={ad.basicInfo.id}
                            adGroupId={adGroup.id}
                            ad={ad}
                            refetch={adGroupRefetch}
                            onDelete={handleDeleteClick}
                          />
                        )
                      })
                    ) : (
                      <p className={styles.noSelectedStyng}>There are no ads in group</p>
                    )}
                  </div>
                </div>
                <div className={styles.submitContainer}>
                  <Button
                    data-test="cancel-button"
                    variant={ButtonVariant.OUTLINED}
                    onClick={() => navigationStore.goToPage(RadioAdGroupsRoute.path)}
                  >
                    {Text.common.cancel}
                  </Button>
                  <Button data-test="submit-button" onClick={formik.handleSubmit}>
                    {Text.common.save}
                  </Button>
                </div>
              </div>
            </React.Fragment>
          )}
        </Box>
      )}
    </React.Fragment>
  )
}

export default observer(RadioAdGroup)
