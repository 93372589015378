/* eslint-disable jsx-control-statements/jsx-for-require-each */
import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { useMutation } from 'react-query'
import { Duration } from 'luxon'

import useStores from '../../../common/hook/useStore'
import { getCustomErrorNotificationByCode } from '../../../ui/Snackbar/SnackbarHelper'

import { RadioAdCreateRoute, RadioAdEditRoute } from '../..'

import RadioAdsStore, { useAdsListQuery } from '../../RadioAdsStore'

import SectionFilters from 'ui/SectionFilters/SectionFilters'
import Table from '../../../ui/Table/Table'
import Pagination from '../../../ui/Pagination/Pagination'
import Button from '../../../ui/Button/Button'
import Spinner from 'ui/Spinner/Spinner'
import JsonDataModal from '../../../ui/JsonDataModal/JsonDataModal'
import DeleteModal from '../../../ui/DeleteModal/DeleteModal'
import Text from '../../../locale/strings'

import { truncateText } from 'common/utils'
import API from '../../../common/api'
import { RadioAd } from '../../../common/api/radioAds/radioAds'

import { ApiStatuses, JsonMetadataModalDataTypes, INITIAL_PAGE, FilterFields } from '../../../common/constants'

import styles from './RadioAdsTable.module.scss'

interface RadioAdsTableProps {
  radioAdsStore: RadioAdsStore
}

const RadioAdsTable = ({ radioAdsStore }: RadioAdsTableProps) => {
  const { navigationStore, notificationsStore } = useStores()
  const { data, status, refetch } = useAdsListQuery(radioAdsStore)
  const [numberOfPages, setNumberOfPages] = useState<number>(INITIAL_PAGE)
  const radioAds = data?.ads ?? []
  const [selectedAd, setSelectedAd] = useState<RadioAd | null>(null)
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)
  const [jsonDataModal, setJsonDataModal] = useState<boolean>(false)

  const onDeleteMutation = useMutation(
    () => {
      return API.radioAds.deleteAd({ adId: selectedAd?.id })
    },
    {
      onSuccess: () => {
        setShowDeleteModal(false)

        notificationsStore.successNotification('Ad successfully deleted')
        refetch()
      },
      onError: (error: any) => {
        const errorObj = JSON.stringify(error)
        const parseObj = JSON.parse(errorObj)
        const errorCode = parseObj.errorCode
        const errorMessage = getCustomErrorNotificationByCode(errorCode)

        notificationsStore.errorNotification(errorMessage)
      },
    },
  )

  const handleOpenAdJsonModal = (id: string) => {
    const ad = radioAds.filter((ad: RadioAd) => ad.id === id)

    setSelectedAd(ad[0])
    setJsonDataModal(true)
  }

  const handleCloseAdJsonModal = () => {
    setSelectedAd(null)
    setJsonDataModal(false)
  }

  useEffect(() => {
    return () => {
      radioAdsStore.changePage(INITIAL_PAGE)
    }
  }, [])

  useEffect(() => {
    if (data?.pageCount) {
      setNumberOfPages(data.pageCount)
    }
  }, [data])

  const handleCreateRadioAd = () => {
    navigationStore.goToPage(RadioAdCreateRoute.path)
  }

  const handleEditClick = (adId: string) => {
    navigationStore.goToPage(RadioAdEditRoute.getUrl({ id: adId }))
  }

  const adColumns = [
    'Id',
    'Name',
    'Countries of availability',
    'Duration',
    'Age restriction',
    'Bound to ad group within',
    'External Metadata',
    'Actions',
  ]

  const dataTable = radioAds.map((ad: RadioAd) => [
    ad.id,
    truncateText(ad.name),
    ad.availability.join(' | '),
    Duration.fromISO(ad.duration).toFormat('mm:ss'),
    ad.ageRestriction,
    `${ad.companyName} / ${ad.applicationName} / ${ad.adGroupName}`,
    ad.metadata === null || ad.metadata === 'null' ? 'NULL' : ad.metadata,
  ])

  const handleChangePage = (value: number) => {
    radioAdsStore.changePage(value)
  }

  const handleDeleteClick = (id: string) => {
    const ad = radioAds.filter((ad: RadioAd) => ad.id === id)

    setSelectedAd(ad[0])
    setShowDeleteModal(true)
  }

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false)
    setSelectedAd(null)
  }

  const handleDeleteAd = () => {
    onDeleteMutation.mutate()
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Button data-test="create-button" onClick={() => handleCreateRadioAd()}>
          {Text.radioAds.create}
        </Button>
      </div>
      <SectionFilters
        sectionStore={radioAdsStore}
        filters={[FilterFields.ID, FilterFields.NAME, FilterFields.AGE_RESTRICTION, FilterFields.AVAILABILITY]}
      />
      <div className={styles.tableWrapper}>
        {status === ApiStatuses.LOADING ? (
          <Spinner />
        ) : (
          <Table
            jsonData
            hiddenColumns={['External Metadata']}
            columns={adColumns}
            data={dataTable}
            onJsonDataClick={handleOpenAdJsonModal}
            onDeleteClick={handleDeleteClick}
            onEditClick={handleEditClick}
          />
        )}

        {numberOfPages > 0 && (
          <div>
            <div className={styles.paginationContainer}>
              <div className={styles.pagination}>
                <Pagination page={radioAdsStore.page} count={numberOfPages} onChange={handleChangePage} />
              </div>
            </div>
          </div>
        )}
      </div>
      {jsonDataModal && (
        <JsonDataModal
          dataTypeId={selectedAd?.id}
          data={selectedAd?.metadata}
          dataType={JsonMetadataModalDataTypes.AD}
          open={jsonDataModal}
          refetch={refetch}
          handleClose={handleCloseAdJsonModal}
        />
      )}
      {showDeleteModal && (
        <DeleteModal
          open={showDeleteModal}
          headerContent={Text.radioAds.deleteAdTitle}
          handleClose={handleCloseDeleteModal}
          text={Text.radioAds.deleteAd}
          handleSubmit={handleDeleteAd}
        />
      )}
    </div>
  )
}

export default observer(RadioAdsTable)
