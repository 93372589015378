/* eslint-disable jsx-control-statements/jsx-for-require-each */
import React, { useEffect, useState, useRef } from 'react'
import { useMutation } from 'react-query'
import { observer } from 'mobx-react'
import { useParams } from 'react-router-dom'
import { useFormik } from 'formik'
import * as yup from 'yup'
import * as R from 'ramda'
import { Duration } from 'luxon'

import useStores from 'common/hook/useStore'
import { getCustomErrorNotificationByCode } from 'ui/Snackbar/SnackbarHelper'

import { RadioAdsRoute } from '../..'
import { useAdQuery } from '../../RadioAdsStore'
import { useAdGroupsListQuery } from 'radioAdGroups/hooks'
import { useTerritoriesQuery } from 'territories/hooks'

import {
  Toolbar,
  Typography,
  Box,
  Chip,
  FormControl,
  Autocomplete,
  TextField as MuiTextField,
  Checkbox,
} from '@mui/material'

import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import Text from 'locale/strings'
import TextField from 'ui/TextField/TextField'
import Select from 'ui/Select/Select'
import Button, { ButtonVariant } from 'ui/Button/Button'
import Spinner from 'ui/Spinner/Spinner'

import API from 'common/api'
import { ApiStatuses } from 'common/constants'
import { OptionType } from 'common/api/common/common'
import { RadioAdRequest } from 'common/api/radioAds/radioAds'
import { RadioAdGroup } from 'common/api/radioAdGroups/radioAdGroups'
import { Territory } from 'common/api/territory/territory'

import styles from './RadioAd.module.scss'

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />

const schema = yup.object().shape({
  adName: yup.string().required('Name is required').max(30, 'Name must be at most 30 characters'),
  adGroupId: yup.string().required('Ad Group is required'),
  ageRestriction: yup.number().typeError('Must be a number').min(1, 'Field value must be greater than or equal to 1'),
})

interface ParamProps {
  id?: string
}

interface Ad {
  adName: string
  availability: string[]
  adGroupId: string
  ageRestriction: number | string
  fileId: string
  duration: string
}

const RadioAd = () => {
  const { navigationStore, notificationsStore } = useStores()
  const [adGroupOptions, setAdGroupOptions] = useState<OptionType[]>([])
  const { id = '' } = useParams<ParamProps>()

  const { data: adGroups, status: adGroupsStatus } = useAdGroupsListQuery()
  const { data: territories, status: territoriesStatus } = useTerritoriesQuery()
  const { data: ad } = useAdQuery(id)

  const [allTerritories, setAllTerritories] = useState<Territory[]>([])
  const [selectedTerritories, setSelectedTerritories] = useState<Territory[]>([])
  const [currentPlaylistId, setCurrentPlaylistId] = useState<string>('')

  const audioRef = useRef<any>(null)
  const [url, setUrl] = useState('')
  const [adFile, setAdFile] = useState<Nullable<File>>(null)
  const [adError, setAdError] = useState<boolean>(false)
  const [adFormatError, setAdFormatError] = useState<boolean>(false)
  const [duration, setDuration] = useState<number>()
  const [availabilitiesError, setAvailabilitiesError] = useState<boolean>(false)

  const [initialValues, setInitialValues] = useState({
    adName: '',
    availability: [],
    adGroupId: '',
    ageRestriction: '',
    fileId: '',
    duration: '',
  })

  const initialRadioAd = id !== '' ? ad : null

  const mutationAd = useMutation<any, Error, RadioAdRequest>(
    (body: RadioAdRequest) => {
      if (!R.isNil(initialRadioAd)) {
        return API.radioAds.editAd(body)
      }

      return API.radioAds.createAd(body)
    },
    {
      onSuccess: () => {
        resetForm()
        navigationStore.goToPage(RadioAdsRoute.path)

        !R.isNil(initialRadioAd)
          ? notificationsStore.successNotification('Ad successfully edited')
          : notificationsStore.successNotification('Ad successfully added')
      },
      onError: (error: any) => {
        const errorObj = JSON.stringify(error)
        const parseObj = JSON.parse(errorObj)
        const errorCode = parseObj.errorCode
        const errorMessage = getCustomErrorNotificationByCode(errorCode)

        notificationsStore.errorNotification(errorMessage)
      },
    },
  )

  useEffect(() => {
    if (!R.isNil(initialRadioAd)) {
      setInitialValues({
        adName: initialRadioAd.name,
        availability: initialRadioAd.availability,
        adGroupId: initialRadioAd.adGroupId,
        ageRestriction: initialRadioAd.ageRestriction,
        fileId: '',
        duration: Duration.fromISO(initialRadioAd.duration).toFormat('HH:mm:ss'),
      })

      setSelectedTerritories(initialRadioAd.availability)
      setFieldValue('adGroupId', initialRadioAd.adGroupId)
      setUrl(initialRadioAd.adTrackUrl)
    }
  }, [ad])

  useEffect(() => {
    const result = adGroups?.adGroups ?? []

    const formatedResult = result.map((adGroup: RadioAdGroup) => {
      return {
        label: `${adGroup.companyName} / ${adGroup.applicationName} / ${adGroup.playlistName}`,
        value: adGroup.id,
      }
    })

    setAdGroupOptions(formatedResult)
  }, [adGroups])

  useEffect(() => {
    if (territories?.territories) {
      setAllTerritories(territories?.territories)
    }
  }, [territories])

  const handlePresubmit = () => {
    if (selectedTerritories.length === 0) {
      setAvailabilitiesError(true)
    }

    if (R.isNil(adFile) && !url) {
      setAdError(true)
    }

    formik.handleSubmit()
  }

  const handleSubmit = (values: Ad) => {
    let uploadId: string
    let uploadUrl: string

    if (selectedTerritories.length === 0 || (R.isNil(adFile) && !url)) {
      return
    }

    setAvailabilitiesError(false)
    setAdError(false)

    const getDataForAdUpload = API.radioAds.getUploadData({
      fileContentType: 'AD',
    })

    if (R.isNil(initialRadioAd)) {
      getDataForAdUpload
        .then((response) => {
          uploadId = response.id
          uploadUrl = response.uploadUrl

          return fetch(uploadUrl, {
            method: 'PUT',
            body: adFile,
          })
        })
        .then(() => {
          mutationAd.mutate({
            adGroupId: values.adGroupId,
            playlistId: currentPlaylistId,
            name: values.adName.trim(),
            availability: selectedTerritories.map((territory) => territory.territoryCode),
            ageRestriction: values.ageRestriction,
            fileId: uploadId,
            duration,
          })
        })
    } else if (R.isNil(adFile)) {
      mutationAd.mutate({
        id: initialRadioAd.id,
        adGroupId: values.adGroupId,
        name: values.adName.trim(),
        availability: selectedTerritories.map((territory) => territory.territoryCode),
        ageRestriction: values.ageRestriction,
        duration,
      })
    } else {
      getDataForAdUpload
        .then((response) => {
          uploadId = response.id
          uploadUrl = response.uploadUrl

          return fetch(uploadUrl, {
            method: 'PUT',
            body: adFile,
          })
        })
        .then(() => {
          mutationAd.mutate({
            id: initialRadioAd.id,
            adGroupId: values.adGroupId,
            name: values.adName.trim(),
            availability: selectedTerritories.map((territory) => territory.territoryCode),
            ageRestriction: values.ageRestriction,
            fileId: uploadId,
            duration,
          })
        })
    }
  }

  const formik = useFormik<Ad>({
    initialValues: initialValues,
    validationSchema: schema,
    validateOnBlur: false,
    validateOnChange: false,
    enableReinitialize: true,
    onSubmit: handleSubmit,
  })

  const { values, resetForm, setFieldValue } = formik

  const handleChangeTerritories = (e: React.SyntheticEvent, value: Territory[]) => {
    setSelectedTerritories(value)
    setAvailabilitiesError(false)
  }

  const handleDeleteTerritory = (value: any) => {
    setSelectedTerritories((territoryArray) =>
      territoryArray.filter((territoryOption) => territoryOption.territoryCode !== value),
    )
  }

  const handleChangeAdGroup = (value: string) => {
    if (typeof adGroups !== 'undefined') {
      const adGroupObjForEdit: RadioAdGroup | undefined = adGroups.adGroups.find((adGroup: any) => adGroup.id === value)
      const playlistIdForEdit: string = adGroupObjForEdit?.playlistId ?? ''

      setFieldValue('adGroupId', value)
      setCurrentPlaylistId(playlistIdForEdit)
    }
  }

  useEffect(() => {
    if (!R.isNil(adFile)) {
      const url = URL.createObjectURL(adFile)

      setUrl(url)

      if (audioRef.current) {
        audioRef.current.load()
      }
    }
  }, [adFile])

  const handleAdUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const file = event.target.files[0]

      if (file.type === 'audio/mpeg' || file.type === 'audio/vnd.dlna.adts') {
        setAdFile(file)
        setAdError(false)
      } else {
        setAdFormatError(true)

        setTimeout(() => setAdFormatError(false), 3000)
      }
    }
  }

  const handleLoadedMetadata = (event: any) => {
    setDuration(event.target.duration)
  }

  return (
    <React.Fragment>
      {id !== '' && R.isNil(initialRadioAd) ? (
        <Spinner />
      ) : (
        <Box>
          {adGroupsStatus === ApiStatuses.LOADING && territoriesStatus === ApiStatuses.LOADING ? (
            <Spinner />
          ) : (
            <React.Fragment>
              <Toolbar className={styles.toolbar}>
                <Typography sx={{ ml: 2, flex: 1, color: '#fff' }} variant="h6" component="div">
                  {!R.isNil(initialRadioAd) ? Text.page.ad.editAd : Text.page.ad.createAd}
                </Typography>
              </Toolbar>
              <div className={styles.container}>
                <div className={styles.form}>
                  <div className={styles.leftWrapper}>
                    <TextField
                      fullWidth
                      data-test="ad-name-field"
                      label={Text.fields.name + ' *'}
                      name="adName"
                      value={values.adName}
                      error={formik.errors.adName}
                      inputProps={{ maxLength: 30 }}
                      onChange={formik.handleChange}
                    />
                    <FormControl fullWidth className={styles.customSelect}>
                      <div className={styles.autocompleteWrapper}>
                        <Autocomplete
                          multiple
                          disableCloseOnSelect
                          data-test="available-countries-field"
                          id="availableCountries"
                          className={styles.autocompleteField}
                          isOptionEqualToValue={(option, value) => option.territoryCode === value.territoryCode}
                          value={selectedTerritories}
                          options={allTerritories}
                          getOptionLabel={(option: Territory) => option.territoryName}
                          renderOption={(props, option, { selected }) => (
                            <li {...props}>
                              <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                              />
                              {option.territoryName}
                            </li>
                          )}
                          renderTags={() => null}
                          renderInput={(params) => (
                            <MuiTextField {...params} label="Choose territories" placeholder="Search" />
                          )}
                          onChange={handleChangeTerritories}
                        />
                        {selectedTerritories.length > 0 && (
                          <Box className={styles.autocompleteFieldValues}>
                            {selectedTerritories.map((option) => (
                              <Chip
                                className={styles.autocompleteFieldValue}
                                key={option.territoryCode}
                                label={option.territoryName}
                                onDelete={() => handleDeleteTerritory(option.territoryCode)}
                              />
                            ))}
                          </Box>
                        )}
                      </div>
                      <If condition={availabilitiesError}>
                        <span className={styles.customError}>{Text.common.fieldIsRequired}</span>
                      </If>
                    </FormControl>
                    {adGroupsStatus === ApiStatuses.SUCCESS && adGroupOptions.length > 0 ? (
                      <Select
                        fullWidth
                        data-test="ad-group-field"
                        className={styles.customSelect}
                        label={Text.page.ad.boundAdGroup + ' *'}
                        name="adGroupId"
                        value={values.adGroupId}
                        options={adGroupOptions}
                        error={formik.errors.adGroupId}
                        onChange={handleChangeAdGroup}
                      />
                    ) : (
                      <p className={styles.selectTextAlt}>There are no available ad groups</p>
                    )}
                    <TextField
                      fullWidth
                      data-test="age-restriction-field"
                      type="number"
                      label={Text.common.ageRestriction}
                      name="ageRestriction"
                      value={values.ageRestriction}
                      error={formik.errors.ageRestriction}
                      InputProps={{
                        inputProps: {
                          min: 1,
                        },
                      }}
                      onChange={formik.handleChange}
                    />
                  </div>
                  <div className={styles.rightWrapper}>
                    <div>
                      <label htmlFor="file-ad">
                        <input
                          hidden
                          id="file-ad"
                          type="file"
                          accept="audio/aac, audio/mp3"
                          value=""
                          onChange={handleAdUpload}
                        />
                        <Button isUpload data-test="upload-ad-button" className={styles.uploadButton}>
                          {Text.page.ad.uploadAd}
                        </Button>
                        <If condition={adError && R.isNil(initialRadioAd)}>
                          <span className={styles.customError}>{Text.page.ad.adError}</span>
                        </If>
                        <If condition={adFormatError}>
                          <span className={styles.customError}>{Text.page.ad.supportedFiles}</span>
                        </If>
                      </label>
                      <If condition={!R.isNil(adFile)}>
                        <span>{adFile?.name}</span>
                      </If>
                    </div>
                    {url && (
                      <audio
                        controls
                        controlsList="nodownload"
                        preload="metadata"
                        ref={audioRef}
                        className={styles.audioPreview}
                        onLoadedMetadata={handleLoadedMetadata}
                      >
                        <source src={url} />
                      </audio>
                    )}
                  </div>
                </div>

                <div className={styles.submitContainer}>
                  <Button
                    data-test="cancel-button"
                    variant={ButtonVariant.OUTLINED}
                    onClick={() => navigationStore.goToPage(RadioAdsRoute.path)}
                  >
                    {Text.common.cancel}
                  </Button>
                  <Button data-test="submit-button" onClick={handlePresubmit}>
                    {Text.common.save}
                  </Button>
                </div>
              </div>
            </React.Fragment>
          )}
        </Box>
      )}
    </React.Fragment>
  )
}

export default observer(RadioAd)
