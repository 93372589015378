import React, { useState } from 'react'
import { useMutation } from 'react-query'
import Storage from '../../common/storage'

import Modal from '@mui/material/Modal'
import Spinner from '../../ui/Spinner/Spinner'
import Select from '../../ui/Select/Select'
import Button, { ButtonVariant } from '../../ui/Button/Button'
import Text from '../../locale/strings'

import useStores from '../../common/hook/useStore'
import { getCustomErrorNotificationByCode } from '../../ui/Snackbar/SnackbarHelper'

import { OptionType } from '../../common/api/common/common'
import { TrackDownloadValues, RadioTrackDownloadRequest } from '../../common/api/track/track'

import styles from './downloadTrackModal.module.scss'

const ACCESS_TOKEN = 'accessToken'
const DEFAULT_FORMAT = 'MP3_STREAM_256'

interface StyngProps {
  open: boolean
  dataForTrackDownloadModal: TrackDownloadValues
  handleClose: () => void
}

const fileFormatOptions: OptionType[] = [
  { label: 'MP3 Stream 256 kbps cbr', value: 'MP3_STREAM_256' },
  { label: 'MP3 Stream 320 kbps cbr', value: 'MP3_STREAM_320' },
  { label: 'MP3 Download 256 kbps cbr', value: 'MP3_DOWNLOAD_256' },
  { label: 'MP3 Download 320 kbps cbr', value: 'MP3_DOWNLOAD_320' },
  { label: 'AAC Download 64 kbps cbr', value: 'MP4_DOWNLOAD_64' },
]

const DownloadTrackModal = ({ open, dataForTrackDownloadModal, handleClose }: StyngProps) => {
  const { notificationsStore } = useStores()
  const accessToken = Storage.get(ACCESS_TOKEN)
  const [selectedFileFormatOption, setSelectedFileFormatOption] = useState<string>(DEFAULT_FORMAT)
  const [showSpinner, setShowSpinner] = useState<boolean>(false)

  const mutationDownloadTrack = useMutation<any, Error, RadioTrackDownloadRequest>(
    async (body: RadioTrackDownloadRequest) => {
      await fetch(
        // eslint-disable-next-line max-len
        `/api/v1/cms/tracks/download?mediaNetId=${body.trackMediaNetId}&fileFormat=${body.fileFormat}`,
        {
          method: 'GET',
          headers: new Headers({
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/octet-stream',
          }),
        },
      )
        .then((response) => {
          if (!response.ok) {
            return response.json().then((data) => {
              return data
            })
          }

          return response.blob().then((blob) => {
            return {
              raw: blob,
              contentDisposition: response.headers.get('Content-Disposition'),
            }
          })
        })
        .then((data) => {
          if (data.errorCode) {
            handleErrorResponse(data.errorCode)
          } else {
            downloadTrackAndCloseModal(
              data,
              dataForTrackDownloadModal.artist,
              dataForTrackDownloadModal.title,
              dataForTrackDownloadModal.mediaNetId,
            )
          }
        })
        .catch((error) => {
          const errorObj = JSON.stringify(error)
          const parseObj = JSON.parse(errorObj)
          const errorCode = parseObj.errorCode

          handleErrorResponse(errorCode)
        })
    },
  )

  const downloadTrackAndCloseModal = (data: any, artist: string, trackTitle: string, trackMediaNetId: string) => {
    const fileName = data?.contentDisposition?.split('filename=').pop().replaceAll('"', '')
    const url = window.URL.createObjectURL(data.raw)
    const downloadLink = document.createElement('a')

    const fileFormat = selectedFileFormatOption.startsWith('MP4') ? '.mp4' : '.mp3'
    const backupFileName = `${artist}-${trackTitle}-${trackMediaNetId}${fileFormat}`

    document.body.appendChild(downloadLink)
    downloadLink.href = url
    downloadLink.download = fileName || backupFileName
    downloadLink.click()
    window.URL.revokeObjectURL(url)

    notificationsStore.successNotification(`File ${fileName} finished downloading`)

    setShowSpinner(false)

    setSelectedFileFormatOption(DEFAULT_FORMAT)
    handleClose()
  }

  const handleErrorResponse = (errorCode: number) => {
    const errorMessage = getCustomErrorNotificationByCode(errorCode)

    notificationsStore.errorNotification(errorMessage)

    setShowSpinner(false)
  }

  const handleChangeDownloadFormat = (value: any) => {
    setSelectedFileFormatOption(value)
  }

  const handleTrackDownload = () => {
    const body = {
      trackMediaNetId: dataForTrackDownloadModal.mediaNetId,
      fileFormat: selectedFileFormatOption,
    }

    mutationDownloadTrack.mutate(body)
    setShowSpinner(true)
  }

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      onBackdropClick={handleClose}
    >
      <div className={styles.container}>
        <div className={styles.header}>{'Download track'}</div>
        <div className={styles.trackIntro}>
          {dataForTrackDownloadModal?.cover && (
            <div className={styles.trackCoverWrapper}>
              <div
                className={styles.trackCover}
                style={{
                  background: `url(${dataForTrackDownloadModal?.cover})`,
                }}
              />
            </div>
          )}

          <div className={styles.textWrapper}>
            <span className={styles.title}>{dataForTrackDownloadModal?.title}</span>
            <span className={styles.artist}>by {dataForTrackDownloadModal?.artist}</span>
          </div>
        </div>
        <div className={styles.selectWrapper}>
          <h5 className={styles.label}>Choose track format</h5>
          <Select
            fullWidth
            value={selectedFileFormatOption}
            options={fileFormatOptions}
            className={styles.customSelect}
            onChange={(e) => handleChangeDownloadFormat(e)}
          />
        </div>

        {showSpinner ? (
          <div className={styles.spinnerContainer}>
            <Spinner />
          </div>
        ) : (
          <div className={styles.submitContainer}>
            <Button className={styles.downloadButton} onClick={handleTrackDownload}>
              Download track
            </Button>
            <Button variant={ButtonVariant.OUTLINED} onClick={handleClose}>
              {Text.common.cancel}
            </Button>
          </div>
        )}
      </div>
    </Modal>
  )
}

export default DownloadTrackModal
